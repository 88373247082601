const formatEventDate = (startDate, endDate) => {
  if (!startDate || !endDate) return "";

  const optionsDate = {
    weekday: "long",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  };
  const optionsTime = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  };

  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start) || isNaN(end)) return "";

  const startDateFormatted = start.toLocaleDateString("en-US", optionsDate);
  const startTimeFormatted = start
    .toLocaleTimeString("en-US", optionsTime)
    .replace("AM", "am")
    .replace("PM", "pm");
  const endDay = end.getDate();

  return `${startDateFormatted} - ${endDay} ${startTimeFormatted}`;
};

const formatDateTime = (isoString) => {
  if (!isoString) return "";

  const date = new Date(isoString);
  if (isNaN(date)) return "";

  const dateOptions = {
    weekday: "short",
    day: "numeric",
    month: "short",
    timeZone: "UTC",
  };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "UTC",
  };

  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  return `${formattedDate}, ${formattedTime}`;
};

const formatDateRange = (startDate, endDate, language) => {
  console.log("start date", startDate);
  if (!startDate || !endDate) return "";

  const start = new Date(startDate);

  console.log("start date2", start);
  const end = new Date(endDate);

  if (isNaN(start) || isNaN(end)) return "";

  const localeMap = {
    en: "en-US",
    es: "es-ES",
    ger: "de-DE",
  };

  const locale = localeMap[language] || "en-US";

  const startDay = start.getUTCDate();
  const endDay = end.getUTCDate();
  const startMonth = start.toLocaleDateString(locale, {
    month: "short",
    timeZone: "UTC",
  });
  const endMonth = end.toLocaleDateString(locale, {
    month: "short",
    timeZone: "UTC",
  });

  console.log("function start", start);
  console.log("function end", end);

  return startDay === endDay
    ? `${startDay} ${startMonth}`
    : startMonth === endMonth
    ? `${startDay} - ${endDay} ${startMonth}`
    : `${startDate} - ${endDate}`;
};

const capitalize = (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const getDurationString = (durations, language) => {
  if (!durations || durations.length === 0) return "";

  const conjunctions = {
    en: " or ",
    es: " o ",
    ger: " oder ",
  };

  const daysWord = {
    en: " days",
    es: " días",
    ger: " Tage",
  };

  const conjunction = conjunctions[language] || " or ";
  const days = daysWord[language] || " days";

  return (
    (durations.length > 1
      ? durations.slice(0, -1).join(", ") +
        conjunction +
        durations[durations.length - 1]
      : durations[0]) + days
  );
};

const utils = {
  formatEventDate,
  formatDateTime,
  formatDateRange,
  capitalize,
  getDurationString,
};

export default utils;
